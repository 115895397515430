.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  min-width: 196px;
  background-color: #151b26;
}

.sidebar__sp {
  background-color: #151b26;
}

.sidebar.sidebar--admin,
.sidebar__sp.sidebar__sp--admin {
  background-color: #151b26;
  // background-color: #f2f2f2;
}

.sidebar.sidebar--corp,
.sidebar__sp.sidebar__sp--corp {
  background-color: #000;
}

.sidebar.sidebar--user,
.sidebar__sp.sidebar__sp--user {
  background-color: #000;
}

.sidebar__link {
  color: #cbd4db;
  // color: #000;
  transition: all 0.1s;
}

.sidebar__link:hover {
  color: #151b26;
  opacity: 80%;
  background: rgba($color: #cbd4db, $alpha: 0.8);
}

.sidebar__logo {
  max-width: 156px;
}

#svg-color {
  fill: #cbd4db;
  // fill: #000;
}
